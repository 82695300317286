import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import LoadingScreen from '../components/Animations/loadingScreen/LoadingScreen'
import Capabilities from '../components/Animations/Capabilities/Capabilities'
import IntroSection from '../components/Animations/introSection/introSection'
import Industries from '../components/Animations/Industries/industries'
import Carousel from '../components/Animations/CaseStudies/CaseStudies'
import Trends from '../components/Animations/Trends/Trends';

import "../css/index-page.scss";


export const IndexPageTemplate = ({
  industriesList,
  capabilitiesList,
  work,
  trends
}) => {

  return (
    <div className='home-page-wrapper'>
      <IntroSection />
      <Industries />
      <Capabilities capabilitiesList={capabilitiesList} />
     {/* <Carousel work={work} /> */}
      {/* <Trends trends={trends} /> */}
    </div>
  )
}



IndexPageTemplate.propTypes = {
  bannerVideo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bannerTitle: PropTypes.string,
  secondHeader: PropTypes.string,
  secondHeaderParagraph: PropTypes.string,
  blueBoxText: PropTypes.string,
  secondVideo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  industriesList: PropTypes.array,
  capabilitiesList: PropTypes.array,
  work: PropTypes.array,
  trends: PropTypes.array,
  date: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      NavBackgroundColor='black'
      NavMenuIcon='/img/shared/MenuBarsWhite.png'
      NavIncLabLogo='/img/shared/whiteTILtext.png'
      NavFontColor='white'
      footerFontColor='white'
      footerBackground='black'
      footerIcons={{ youtube: '/img/footer/youtube-white.png', twitter: '/img/footer/twitter-white.png', linkedin: '/img/footer/linkedin-white.png' }}
      title={frontmatter.bannerTitle}
      description={frontmatter.secondHeaderParagraph}
    >
      <IndexPageTemplate
        bannerVideo={frontmatter.bannerVideo}
        bannerTitle={frontmatter.bannerTitle}
        secondHeader={frontmatter.secondHeader}
        secondHeaderParagraph={frontmatter.secondHeaderParagraph}
        blueBoxText={frontmatter.blueBoxText}
        secondVideo={frontmatter.secondVideo}
        industriesList={frontmatter.industriesList}
        capabilitiesList={frontmatter.capabilitiesList}
        work={frontmatter.work}
        trends={frontmatter.trends}
        date={frontmatter.data}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        bannerVideo 
        bannerTitle
        secondHeader
        secondHeaderParagraph
        blueBoxText
        secondVideo
        industriesList {
          title
          link
        }
        capabilitiesList {
          title
          description
          link
        }
        trends {
          title
          link
          thumbnail {
            extension
            publicURL 
          }
        }
        work {
          client
          projectName
          link
          thumbnail {
            extension
            publicURL 
          }
          technologies
        }
        date
      }
    }
  }
`