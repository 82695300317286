import React from "react";
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import "./industries.scss";

const Industries = () => {
  
  const { scrollYProgress } = useViewportScroll();

  const isBrowser = typeof window !== "undefined";
  let isMobile = false;
  if (isBrowser) {
    const { innerWidth: width } = window;
    isMobile = width <= 768;
  }

  const opacity = useTransform(scrollYProgress, [0, 0.3, 1], [0.25, 1, 0.25]); // Example transformation  
  
  return (
   
    <div className="home-industries-wrapper">
      <div className="section-header">
        <span className="thin">Our</span>
        {' '}Platforms
      </div>
      <motion.div className="platform-container" style={{ opacity }}>
        {["INTELLIGENT PLATFORM PROTOTYPING & INTEGRATION", "ALL DOMAIN MISSION PLANNING PLATFORMS", "AI/ML ALGORITHMS FOR GOVERNMENT"].map(title => (
          <div className="platform-card" key={title}>
            <div className="title">{title}</div>
            <p>
              {title === "INTELLIGENT PLATFORM PROTOTYPING & INTEGRATION" && "Our product and engineering teams deploy the industry-leading user-centered 'TIL Process' to develop and integrate innovative solutions that address complex government and commercial challenges, delivering results at the speed of relevance."}
              {title === "ALL DOMAIN MISSION PLANNING PLATFORMS" && "Our mission platforms address operator needs for faster and more precise planning, increased and real-time awareness, with an architecture that is both modular and coordinated to address ever-evolving threats."}
              {title === "AI/ML ALGORITHMS FOR GOVERNMENT" && "Leading-edge engineering and deep subject-matter expertise responds directly to complexity of mission and constant advancement of threats to develop fieldable digital applications."}
            </p>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Industries;