import React from 'react'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import './capabilities.scss'


const Capabilities = ({ capabilitiesList }) => {
    const { scrollYProgress } = useViewportScroll();

    const isBrowser = typeof window !== "undefined";
    let isMobile = false;
    if (isBrowser) {
        const { innerWidth: width } = window;
        isMobile = width <= 768;
    }

    const scaleUp = useTransform(scrollYProgress, [.3, .5, .7], [1.1, 2, 3])
    const opacity = useTransform(scrollYProgress, [.3, .5, .7], [1, .5, 0])

    const mobileScaleUp = useTransform(scrollYProgress, [.2, .5, .6], [2.2, 5, 6])

    return (
        <div className='capabilities-wrapper'>
            <motion.div
                style={{ scale: isMobile ? mobileScaleUp : scaleUp, opacity: opacity }}
            >
                <img className='svg-background' src={"/img/home-animation-sections/Topo 3.svg"} />

            </motion.div>
            <div className='capabilities-content'>
                <div className='section-header'>
                    <span className="thin">Our</span>
                    {' '}Capabilities
                </div>
                <div className='capabilities-list'>
                    {capabilitiesList.map(item => (
                        <div key={item.title} className='capabilities-item'>
                            <div className='capabilities-title'>{item.title}</div>
                            <div className='capabilities-body'>{item.description}</div>
                            <div className='arrow'>
                                <a href={item.link}>
                                    <img src='img/home-animation-sections/right-arrow.png' width={"34px"} />
                                </a>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}


export default Capabilities
