import React from 'react'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import './introSection.scss'

const IntroSection = () => {

    const { scrollYProgress } = useViewportScroll();

    const isBrowser = typeof window !== "undefined";
    let isMobile = false;
    if (isBrowser) {
        const { innerWidth: width } = window;
        isMobile = width <= 768;
    }

    const scaleUp = useTransform(scrollYProgress, [0, 0.2, .4], [1, 2, 3])
    const opacity = useTransform(scrollYProgress, [0, 0.2, .35], [1, .5, 0])

    const mobileScaleUp = useTransform(scrollYProgress, [0, 0.2, .4], [1.5, 4, 5])
    
    const brightness = useTransform(scrollYProgress, [0, 0.5, 1], [0.5, 1, 1.5]);

    return (
        <div className='intro-section-wrapper'>
            <motion.div
                className='svg-container'
                style={{ scale: isMobile ? mobileScaleUp : scaleUp, opacity: opacity, x: isMobile ? -80 : 0 }}
                animate='visible'
                initial="hidden"
            >
                <img className='svg-background' src={"/img/home-animation-sections/Topo 1_mobile.svg"} />

            </motion.div>
            <div className='intro-section-content'>
                <img alt='logo' width='80px' src='/img/home-animation-sections/TheIncLab_Icon_Color.png'></img>
                <div className='content-header trademarked'>The Mission Starts Here</div>
                <p className='content-body'>We design, build and deploy intelligent applications and platforms that have a profound and positive impact on our customers</p>
 
            </div>
        </div>
    )
}
export default IntroSection
